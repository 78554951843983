import { gql } from "@apollo/client";

export const create_deposit_mutation = gql`
  mutation createDeposit($data: deposit_input) {
    createDeposit(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_deposit_mutation = gql`
  mutation updateDeposit($id: String!, $data: deposit_input) {
    updateDeposit(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_deposit_mutation = gql`
  mutation deleteDeposit($id: String!) {
    deleteDeposit(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_demands_query = gql`
  query getDemands(
    $society_demand_id: String
    $society_id: String
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getDemands(
      society_demand_id: $society_demand_id
      society_id: $society_id
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        month
        year

        member {
          name
          member_number
        }
        current_loan_principal
        current_loan_interest
        arrear_loan_interest
        arrear_loan_principal
        penal_interest
        penal_interest_arrear
        deposit_current
        deposit_arrear
        total_demand

        deposit_demands {
          id
          deposit_id
          deposit_current
          deposit_arrear
          total_demand

          deposit {
            deposit_product {
              id
              name
            }
          }
        }
        loan_demands {
          id
          loan_id
          demand_id
          loan {
            id
            outstanding_principal
            loan_request {
              id
              amount
              loan_product {
                id
                name
              }
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_deposit_query = gql`
  query getDeposit($id: String!) {
    getDeposit(id: $id) {
      id
      deposit_type
      account_type
      member_id
      member {
        id
        name
      }
      amount
      tenure_days
      date
      maturity_date
      interest_rate
      interest_payment_frequency
      interest_amount
      address_id
      address {
        door_no
        line_1
        line_2
        line_3
        state_id
        district_id
        region_id
        taluk_id
        pincode
      }
      error {
        message
      }
    }
  }
`;

export const get_demand_generations_query = gql`
 query getDemandGenerations(
  $sub_department_id_list: [String]
  $demand_generation_log_id: String!
  $department_id: String
) {
  getDemandGenerations(
    sub_department_id_list: $sub_department_id_list
    demand_generation_log_id: $demand_generation_log_id
    department_id: $department_id
  ) {
    items {
      id
      demand_date
      sub_department_id
      demand_output
      generated_date
      total_demand
      total_members
      sub_department {
        id
        name
        code
        address
        department {
          id
          code
          name
        }
      }
    }
  }
}
`;

export const get_demand_generations_last_date_only_query = gql`
query getDemandGenerations(
  $sub_department_id_list: [String]
  $demand_generation_log_id: String
  $department_id: String
) {
  get_demand_generations_last_date_only_query: getDemandGenerations(
    sub_department_id_list: $sub_department_id_list
    demand_generation_log_id: $demand_generation_log_id
    department_id: $department_id
  ) {
    items {
      id
      demand_date
      generated_date
    }
  }
}
`;

export const get_demand_generations_list_query = gql`
 query getDemandGenerations(
  $sub_department_id_list: [String]
  $demand_generation_log_id: String!
  $department_id: String
) {
  getDemandGenerations(
    sub_department_id_list: $sub_department_id_list
    demand_generation_log_id: $demand_generation_log_id
    department_id: $department_id
  ) {
    items {
      id
      demand_date
      demand_output
      sub_department_id
      generated_date
      total_demand
      total_members
      sub_department {
        id
        name
        code
        address
        department {
          id
          code
          name
        }
      }
    }
  }
}
`;

export const get_demand_generation_list_query = gql`
  query getAllDemandGeneration(
    $sub_department_id_list: [String]
    $generated_date: String!
    $date: String
    $page_number: Int
    $page_limit: Int
  ) {
    getAllDemandGeneration(
      sub_department_id_list: $sub_department_id_list
      date: $date
      page_number: $page_number
      page_limit: $page_limit
      generated_date: $generated_date
    ) {
      items {
        id
        total_demand
        total_members
        demand_date
        generated_date
        total_count
        sub_department_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const generate_demand_mutation = gql`
  mutation generateDemand($data: demand_input) {
    generateDemand(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const get_demand_generation_list_excel_query = gql`
  query getDemandGenerations($sub_department_id_list: [String], $date: String) {
    getDemandGenerations(
      sub_department_id_list: $sub_department_id_list
      date: $date
    ) {
      items {
        id
        member_number
        deposits {
          id
          deposit_product {
            id
            name
          }
          accumulated_amount
          arrear_amount
          last_deposited_date
          total_generated_interest
          current_unpaid_interest
          total_paid_out_interest
          last_paid_out_interest
          last_interest_paid_date
          deposit_number
          amount
          opening_date
          maturity_date
          rate_of_interest
          imported_accumulated_amount
          imported_date
          current_accumulated_amount
        }
        mail_queue_status {
          status
        }
        surety_loans {
          id
          loan_number
          number_of_months
          rate_of_interest
          penal_interest_percentage
          loan_amount
          disbursed_amount
          disbursed_date
          total_principal_paid
          total_principal_paid
          status
          monthly_principal
          current_principal_balance
          interest_delta
          last_interest_accrual_date
          current_interest_balance
          current_demand_date
          current_overdue_principal_balance
          current_overdue_interest_balance
          current_penal_interest_balance
          current_overdue_penal_interest_balance
          overdue_since
          demand_validity
          surety1 {
            name
            member_number
          }
        }
      }
    }
  }
`;

export const create_demand_collection_mutation = gql`
  mutation createSocietyCollectionExcel(
    $json: create_society_collection_excel_upload_input
  ) {
    createSocietyCollectionExcelUpload(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_demand_mutation = gql`
  mutation createDemandGeneration($sub_department_id: [String]) {
    createDemandGeneration(sub_department_id: $sub_department_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_demand_query = gql`
  query getDemandGeneration($json: get_demand_generation_input) {
    getDemandGeneration(json: $json) {
      id
      sub_department_id
      demand_output
      generated_date
      page_number
      page_limit
      total_count
      demand_date
      total_demand
      demand_collections {
        id
        member_id
        line_items
        sub_department_id
        collection_amount
        collection_date
        member {
          name
          member_number
        }
      }
      sub_department {
        id
        name
        code
        department {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_demand_query = gql`
  query getDemandGeneration($json: get_demand_generation_input) {
    demand: getDemandGeneration(json: $json) {
      id
      sub_department_id
      demand_output
      generated_date
      page_number
      page_limit
      total_count
      demand_date
      total_demand
      demand_collections {
        id
        member_id
        line_items
        sub_department_id
        collection_amount
        collection_date
        member {
          name
          member_number
        }
      }
      sub_department {
        id
        name
        code
        department {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_demand_for_download_query = gql`
  query getDemandGeneration($json: get_demand_generation_input) {
    demand_for_download: getDemandGeneration(json: $json) {
      id
      sub_department_id
      demand_output
      generated_date
      page_number
      page_limit
      total_count
      demand_date
      total_demand
      demand_collections {
        id
        member_id
        line_items
        sub_department_id
        collection_amount
        collection_date
        member {
          name
          member_number
        }
      }
      sub_department {
        id
        name
        code
        department {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_member_demand = gql`
  query getOneMemberDemandGeneration($date: String, $member_id: String) {
    getOneMemberDemandGeneration(date: $date, member_id: $member_id) {
      demand_output
    }
  }
`;

export const query_generated_month_demand_list = gql`
  query getAllDemandGeneration(
    $sub_department_id_list: [String]
    $generated_date: String!
    $page_number: Int
    $page_limit: Int
  ) {
    getAllDemandGeneration(
      sub_department_id_list: $sub_department_id_list
      page_number: $page_number
      page_limit: $page_limit
      generated_date: $generated_date
    ) {
      items {
        id
        total_demand
        total_members
        demand_date
        generated_date
        total_count
        sub_department_id
        sub_department {
          department {
            id
            code
            name
          }
          id
          name
          code
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_demand_generation_logs = gql`
query getDemandGenerationLogs(
  $date: String
  $page_number: Int
  $page_limit: Int
) {
  getDemandGenerationLogs(
    date: $date
    page_limit: $page_limit
    page_number: $page_number
  ) {
    items {
      id
      total_demand
      total_members
      demand_date
      generated_date
    }
    pagination {
      page_number
      page_limit
      total_count
    }
  }
}
`;

