import React, { memo, useEffect, useRef, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import {
  getDepartmentListSelector,
  getDemandGenerationListSelector,
  get_departments,
  get_demand_generations,
  get_society_deposit_product_list,
  query_accounting_date,
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Collapse, DatePicker, Dropdown, Table } from "antd";
import Wrapper from "@views/components/ui/wrapper";
// import Table from "@views/components/ui/ant_table";
import Modal from "@views/components/ui/modal";
import { showToast } from "@helpers/toast";
import { amountFormat } from "@helpers/utils";
import DownloadDemandAsZip from "../download_demand/downloading_demand_zip";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import * as XLSX from "xlsx";
import RecordCollectionExcel from "../../collection/record_collection_excel";
import { formatStatus } from "../../../../helpers/constants";
import BulkMailConfirmation from "./bulk_mail_confirmation";
import {
  get_all_demand_formats,
  get_all_rd_products,
  query_demand_generation_date_only_logs,
  query_demand_generation_logs,
} from "../../../../services/redux";
import DCBReportDownload from "../download_demand/dcb_report";
// import ReportDownload from "./bulk_demand_excel_report";
import { set, truncate, upperCase } from "lodash";
import { useDownloadExcel } from "react-export-table-to-excel";
import { usePageComponentAccess } from "../../../../helpers/auth";
import ReportDownload from "./payofficer_collection_excel";
import {
  demandDisabledRange,
  demand_disabled_range,
  disabled_range,
  exportToExcel,
} from "../../../../helpers/functions";
import DemandGeneratedListCollapse from "./demand_generated_list_collapse";
import { decryptData, encryptData } from "helpers/crypto";
import { useLocation } from "react-router-dom";
import { get_demand_generations_query } from "services/redux/slices/demand/graphql";
import { isNull } from "lodash";

const DemandGenerationList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const moment = require("moment");
  const current_month = moment().format("MMMM");

  let society_id = localStorage.getItem("society_id");
  const [query, setQuery] = useState({});
  const [confirmation_modal, setConfirmationModal] = useState(false);
  const [month, setMonth] = useState();
  const [current_month_demand, setCurrentMonthDemand] = useState("existing");
  const [sub_department, setSubDepartment] = useState([]);
  const [department_id, setDepartmentId] = useState("");
  const [sub_department_id, setSubDepartmentId] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [excel_modal_open, setExcelModalOpen] = useState(false);
  const [email_modal_open, setEmailModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [alreadySelectedData, setAlreadySelectedData] = useState([]);
  const [values, set_values] = useState([]);
  const [show_report_modal, set_show_report_modal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [demandPageLimit, setDemandPageLimit] = useState(10);
  const [demandPageNumber, setDemandPageNumber] = useState(1);
  const [pressed_record_index, set_pressed_record_index] = useState(0);
  const [demands, set_demands] = useState(null);
  const [state, set_state] = useState(null);
  const [log_view_item_id, set_log_view_item] = useState(null);
  const [last_generated_demand_date, set_last_generated_demand_date] =
    useState(null);
  console.log("last_generated_demand_date: ", last_generated_demand_date);

  // for collapse
  const [page_size, set_page_size] = useState(10);
  const [page_number_collapse, set_page_number_collapse] = useState(1);

  const table_ref = useRef();

  const { items: departments, loading: demand_loading } = useSelector(
    getDepartmentListSelector
  );

  const { items, loading } = useDynamicSelector("getDemandGenerations");
  const printRef = useRef(null);
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const { demand_generation_log_id } = useDynamicSelector(
    "demand_generation_log_id"
  );

  const {
    items: demand_for_previous_month,
    loading: demand_for_previous_month_loading,
    pagination: demand_for_previous_month_pagination,
  } = useDynamicSelector("getDemandGenerationLogs");

  const { items: demand_last_date } = useDynamicSelector(
    "demand_generation_date_only_logs"
  );

  // useEffect(()=>{
  //   if(demand_for_previous_month?.length >= 0){
  //     dispatch(dynamicClear("getDemandGenerations"))
  //   }
  // },[demand_for_previous_month])

  const { status: createStatus, error: createError } = useDynamicSelector(
    "uploadCollectionDemand"
  );
  const {
    loading: create_mail_queue_loading,
    status: create_mail_queue_status,
    error: create_mail_queue_error,
  } = useDynamicSelector("createMailQueue");

  useEffect(() => {
    dispatch(get_departments());
    getAccountingDate();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
    getAllDemandsFormatContent();
    getRdLoanProductList();
  }, []);

  useEffect(() => {
    if (month) {
      get_demand_logs_date_only();
    }
  }, [month]);

  useEffect(() => {
    if (demand_last_date) {
      set_last_generated_demand_date({
        demand_date: demand_last_date?.[0]?.demand_date,
        generated_date: demand_last_date?.[0]?.generated_date,
      });
    }
  }, [demand_last_date]);

  useEffect(() => {
    if (month) {
      getDemandLogs();
    }
  }, [month]);

  useEffect(() => {
    if (demand_for_previous_month?.length>0) {
      get_demand();
    } else {
      dispatch(dynamicClear("getDemandGenerations"));
      set_demands([]);
    }
  }, [demand_for_previous_month]);

  useEffect(() => {
    if (items) {
      const mapped = items?.map((item, index) => {
        return { ...item, rowIndex: index };
      });
      set_demands(mapped);
    }
  }, [items]);

  useEffect(() => {
    getDemandLogs();
    show_note();
  }, [month]);

  useEffect(() => {
    if (sub_department?.length === 1) {
      setSubDepartmentId(sub_department?.[0]?.id);
    } else {
      setSubDepartmentId(null);
    }
  }, [sub_department]);

  const getDemandLogs = () => {
    const keys = [{ key: "getDemandGenerationLogs", loading: true }];
    const variables = {
      date: month,
      page_number: page_number_collapse,
      page_limit: page_size,
    };
    dispatch(dynamicRequest(keys, query_demand_generation_logs, variables));
  };

  const get_demand_logs_date_only = () => {
    const keys = [{ key: "demand_generation_date_only_logs", loading: true }];
    const variables = {
      date: month,
      page_number: 1,
      page_limit: 1,
    };
    dispatch(
      dynamicRequest(keys, query_demand_generation_date_only_logs, variables)
    );
  };

  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_demand = (demand_generation_log_id) => {
    if (demand_generation_log_id || demand_for_previous_month?.[0]?.id) {
      let key = [{ key: "getDemandGenerations", loading: true }];
      let query = get_demand_generations_query;
      let variables = {
        demand_generation_log_id:
          demand_generation_log_id || demand_for_previous_month?.[0]?.id || "",
        sub_department_id_list: sub_department_id,
        department_id,
      };
      dispatch(dynamicRequest(key, query, variables));
    }
  };

  const getAllDemandsFormatContent = () => {
    let keys = [{ key: "get_demandPage_formats", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_all_demand_formats, variables));
  };

  useEffect(() => {
    let temp = [];
    if (department_id) {
      temp = departments?.find((x) => x.id === department_id)?.sub_departments;
    } else {
      setSubDepartment(temp);
    }
    temp = temp.map((list) => {
      return {
        id: list?.id,
        name: list?.code ? `${list?.code} - ${list?.name}` : `${list.name}`,
      };
    });
    setSubDepartment(temp);
  }, [department_id]);

  useEffect(() => {
    if (createError) {
      showToast({
        type: "error",
        message: createError?.message?.includes("Couldn't approve")
          ? createError?.message
          : t("something_went_wrong"),
      });
      dispatch(dynamicClear("uploadCollectionDemand"));
      handleExcelModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("collection_upload_success")}`,
      });

      handleExcelModalClose();

      dispatch(dynamicClear("uploadCollectionDemand"));
    } else if (create_mail_queue_status === "Success") {
      // get_demand_generations({
      //   page_limit: page_limit,
      //   page_number: page_number,
      //   date: month,
      //   demand_generation_log_id:
      //     log_view_item_id || demand_for_previous_month?.[0]?.id,
      // });
      get_demand();
    }
  }, [createStatus, createError, create_mail_queue_status]);

  // useEffect(()=>{
  //   console.log("pressed_record_index: ", pressed_record_index,page_number_collapse);
  //   if( pressed_record_index === 0 &&
  //     page_number_collapse === 1){
  //       localStorage.setItem("last_generated_demand_date",encryptData({
  //         demand_date : demand_for_previous_month?.[0]?.demand_date,
  //         generated_date: demand_for_previous_month?.[0]?.generated_date,
  //       }));

  //       const encrypted_state = localStorage.getItem("last_generated_demand_date");
  //       if(encrypted_state){
  //         const state = encrypted_state ? decryptData(encrypted_state) : null;
  //         set_last_generated_demand_date(state)
  //       }
  //     }

  // },[pressed_record_index,page_number_collapse,month])

  const show_note = () => {
    let is_current_month =
      moment(month).format("MMM") ==
      moment(getSocietyAccountingDay?.current_accounting_day).format("MMM");
    if (page_number_collapse != 1) {
      return { show: true, message: t("collection_disabled_tooltip_month") };
    } else if (pressed_record_index != 0 && is_current_month === false) {
      return { show: true, message: t("collection_disabled_tooltip_month") };
    } else if (
      pressed_record_index != 0 &&
      page_number_collapse != 1 &&
      is_current_month === false
    ) {
      return { show: false };
    } else if (is_current_month) {
      return { show: true, message: t("collection_disabled_tooltip") };
    } else {
      return { show: false };
    }
  };

  const handleView = (item, index) => {
    const on_click_view = () => {
      let is_collection_enable = false;
      let is_month_enable =
        moment(month).format("MMM") !==
        moment(getSocietyAccountingDay?.current_accounting_day).format("MMM");

      // if collection is enabled for the first record on the first page
      if (
        pressed_record_index === 0 &&
        page_number_collapse === 1 &&
        is_month_enable
      ) {
        is_collection_enable = true;
      } else {
        // is_collection_enable = is_log_collection_enable && is_month_enable ? ;
        is_collection_enable = false;
      }

      // Encrypt and set the state in sessionStorage
      const generated_demand_state = encryptData({
        is_collection_enable,
        is_month_enable,
        month: moment(month).format("MMMM"),
      });

      sessionStorage.setItem("generatedDemandState", generated_demand_state);

      // Open the new tab
      const newWindow = window.open(
        `/generated-demand-for-society/${item?.id}/${item?.sub_department_id}`,
        "_blank"
      );

      // Post message to the new tab
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.postMessage(
            { is_collection_enable, current_month: is_month_enable },
            window.location.origin
          );
        };
      } else {
        console.error("Failed to open the new window.");
      }
    };

    return (
      <Link onClick={on_click_view} target="_blank">
        {t("view")}
      </Link>
    );
  };

  const handleConfirmationModalOpen = () => {
    setConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModal(false);
  };

  const handleExcelModalOpen = () => {
    setExcelModalOpen(true);
  };
  const handleExcelModalClose = () => {
    setExcelModalOpen(false);
  };

  const handleEMailModalOpen = () => {
    setEmailModalOpen(true);
  };

  const handle_report_modal = () => {
    set_show_report_modal((prevState) => !prevState);
  };
  const handleEmailModalClose = () => {
    setEmailModalOpen(false);
    setAlreadySelectedData([]);
    setAlreadySelectedRows([]);
  };
  const handleActionClick = () => {
    setCollapseOpen(false);
  };

  const disabledDate = (current) => {
    return (
      current &&
      (current < moment("2024-03-01") ||
        current > moment(getSocietyAccountingDay?.current_accounting_day))
    );
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];

    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(Number(date));
    return moment(date).format("DD/MM/YYYY");
  };

  const formatMonth = (value) => {
    return moment.unix(value / 1000).format("MMMM");
  };

  const formatDepartment = (values) => {
    return (
      <VStack space={"2px"}>
        <Text bold>{values?.department.name}</Text>
        <HStack>
          <Text>{values?.name?.trim()}</Text>
          {values?.code && <Text>{` - ${values?.code}`}</Text>}
        </HStack>
      </VStack>
    );
  };

  let column1 = [
    // {
    //   header: t("table:department"),
    //   dataIndex: "sub_department.department.name",
    //   key: "sub_department",
    //   sortable: false,
    //   // formatDisplay: formatDate,
    //   align: "left",
    //   flex: 1,
    // },
    {
      title: t("table:department_subdepartment"),
      dataIndex: "sub_department",
      key: "department",
      formatDisplay: formatDepartment,
      sortable: false,
      align: "left",
      flex: 1,
      render: formatDepartment,
    },
    {
      title: t("table:generated_on"),
      dataIndex: "demand_date",
      key: "generated_accounting_date",
      sortable: false,
      formatDisplay: formatDate,
      align: "left",
      width: 130,
      render: formatDate,
    },
    {
      title: t("table:for_the_month"),
      dataIndex: "demand_date",
      key: "demand_date",
      sortable: false,
      formatDisplay: formatMonth,
      align: "left",
      width: 130,
      render: formatMonth,
    },
    // {
    //   header: t("table:email_status"),
    //   dataIndex: "mail_queue_status",
    //   key: "month",
    //   sortable: false,
    //   formatDisplay: (record) => formatStatus(record?.[0]?.status),
    //   align: "left",
    //   width: 140,
    // },
    // {
    //   header: t("table:generated_on"),
    //   dataIndex: "generated_date",
    //   key: "month",
    //   sortable: false,
    //   formatDisplay: formatDate,
    //   align: "left",
    //   width: 100,
    // },
    {
      title: t("table:total_members"),
      dataIndex: "total_members",
      key: "total_members",
      sortable: false,
      align: "left",
      width: 100,
    },
    {
      title: t("table:total_demand"),
      dataIndex: "total_demand",
      key: "total_demand_amount",
      sortable: false,
      align: "left",
      width: 200,
      formatDisplay: amountFormat,
      render: amountFormat,
    },
    {
      title: t("table:View"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      render: (text, record, index) => {
        return handleView(record, index);
      },
      formatDisplay: handleView,
    },
  ];

  const handleDownloadExcel = () => {
    setShowDownload(true);
  };

  useEffect(() => {
    if (items) {
      const combinedJsonData = items.reduce((acc, currentItem) => {
        let jsonData = JSON.parse(currentItem.demand_output);
        jsonData.department = currentItem.sub_department;
        let re_generate_value = jsonData?.map((list) => {
          return {
            ...list,
            department: currentItem?.sub_department,
          };
        });
        acc = acc.concat(re_generate_value);
        return acc;
      }, []);
      let jsonData = combinedJsonData;
      set_values(jsonData);

      // const worksheet = XLSX.utils.json_to_sheet(inputFormat);
      // const columnWidths = {};
      // Object.keys(worksheet).forEach((key) => {
      //   const column = key.replace(/[0-9]/g, ""); // Extract column letters
      //   const width =
      //     (worksheet[key].v ? worksheet[key].v.toString().length : 10) + 2; // Calculate width based on cell value
      //   columnWidths[column] = Math.max(columnWidths[column] || 0, width); // Store maximum width for each column
      // });

      // // Set column widths
      // worksheet["!cols"] = Object.keys(columnWidths).map((column) => ({
      //   wch: columnWidths[column],
      // }));

      // const workbook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // const outputFilePath = `Demand.xlsx`;

      // XLSX.writeFile(workbook, outputFilePath, {
      //   bookType: "xlsx",
      //   type: "buffer",
      // });
      // });
    }
  }, [items]);

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    get_demand();
  }, [
    // create_mail_queue_status,
    department_id,
    sub_department_id,
  ]);

  useEffect(() => {
    get_demand_generations({
      page_limit: page_limit,
      page_number: page_number,
      date: month,
      demand_generation_log_id:
        log_view_item_id || demand_for_previous_month?.[0]?.id,
    });
  }, [demand_for_previous_month]);

  useEffect(() => {
    setMonth(getSocietyAccountingDay?.current_accounting_day);
  }, [getSocietyAccountingDay]);

  const handleDCBReport = (type, fn, dl, excelData) => {
    var elt = document.getElementById("dcb-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "Demand" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };

        // Convert x?.unique_id to string if it exists in the sheet
        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s"; // Indicate that the cell contains a string
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString(); // Convert to string and prepend with apostrophe
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };

  const handleDCBReport2 = (type, fn, dl, excelData) => {
    var elt = document.getElementById("dcb-report2");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "Demand" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };

        // Convert x?.unique_id to string if it exists in the sheet
        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s"; // Indicate that the cell contains a string
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString(); // Convert to string and prepend with apostrophe
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };
  const { onDownload } = useDownloadExcel({
    currentTableRef: printRef.current,
    filename: `Demand - ${upperCase(moment(month).format("MMM/YYYY"))}`,
    sheet: "Demand",
    excelData: values,
    handleDownload: handleDCBReport,
  });

  const handleSampleCollection = () => {
    let inputFormat = values.map((list) => {
      return {
        ["Pay Officer Code"]: list?.department?.code,
        ["Mem.No"]: list?.member_number,
        ["Amount"]: calculateTotalDemandValue(list),
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Demand - ${upperCase(moment(month).format("MMM/YYYY"))}.xlsx`
    );
  };

  const print_items = [
    {
      key: "normal",
      label: "Simple ",
    },
    {
      key: "expand",
      label: "Detailed",
    },
    {
      key: "pay_officer_demand",
      label: "Pay Officer",
    },
  ];

  const payOfficerDemand = () => {
    let arranged_data = arrangeXLReportData(items);
    exportToExcel(arranged_data ?? [], "Pay Officer Demand");
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["Pay Officer Code"]: data_items[i].sub_department?.code,
        ["Amount"]: data_items[i].total_demand,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  useEffect(() => {
    if (show_report_modal) {
      const interval = setInterval(handle_report_modal, 2000);
      onDownload();
      return () => clearInterval(interval);
    }
  }, [show_report_modal]);

  const onclick_download = (value) => {
    if (value?.key === "normal") {
      handleSampleCollection();
    } else if (value?.key === "pay_officer_demand") {
      payOfficerDemand();
    } else {
      handle_report_modal();
    }
  };

  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  };

  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos;

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand?.interest || 0) +
            Number(demand?.penal_interest || 0) +
            Number(demand?.arrear_monthly_principal || 0) +
            Number(demand?.arrear_interest || 0) +
            Number(demand?.arrear_penal_interest || 0) +
            Number(demand?.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += dueto?.balance_amount || 0;
    }, 0);

    return total;
  };
  const note = show_note();

  return (
    <>
      <Box width="100%" overflow={"hidden"}>
        <HStack space="3">
          <Box flex={1}>
            <Text bold>{t("department")}</Text>
            <SelectFilter options={departments} setField={setDepartmentId} />
          </Box>
          <Box flex={1}>
            <Text bold>{t("sub_department")}</Text>
            <SelectFilter
              value={sub_department_id}
              options={sub_department}
              setField={setSubDepartmentId}
            />
          </Box>
          <Box flex={1}>
            <Text bold>{t("month")}</Text>
            <DatePicker
              defaultValue={moment(month)}
              value={moment(month)}
              format="MMMM"
              picker="month"
              disabledDate={demandDisabledRange()}
              onChange={(e) => {
                setMonth(e);
              }}
              allowClear={false}
            />
          </Box>

          {items?.length ? (
            <>
              <Box alignSelf={"center"} mt="5">
                <DownloadDemandAsZip data={items} />
              </Box>

              <Box alignSelf={"center"} mt="5">
                <Dropdown.Button
                  type="primary"
                  menu={{
                    items: print_items,
                    onClick: onclick_download,
                  }}
                  className="demand-button"
                  style={{ backgroundColor: "#683fe9 !import" }}
                  loading={show_report_modal}
                >
                  {t("download_demand_excel")}
                </Dropdown.Button>
              </Box>
              <Box alignSelf={"center"} mt="5">
                <Button
                  onClick={() => handleExcelModalOpen()}
                  type="primary"
                  className="demand-button"
                >
                  {t("bulk_upload")}
                </Button>
              </Box>
              {/* <Box alignSelf={"center"} mt="5">
                <Button onClick={() => handleEMailModalOpen()} type="primary" className="demand-button" disabled={alreadySelectedRows?.length === 0 ? true : false}>
                  {t("bulk_email")}
                </Button>
              </Box> */}
              {/* <Box alignSelf={"center"} mt="5">
              </Box> */}
            </>
          ) : (
            ""
          )}
        </HStack>

        <div className="demand-collapse-list-view">
          <Box my={3}>
            {/* {demand_for_previous_month?.length > 0 && ( */}
            <Collapse
              accordion
              // activeKey={collapseOpen ? "0" : ""}
              // onChange={toggleCollapse}
            >
              <Collapse.Panel
                header={
                  demand_for_previous_month?.length > 0 ? (
                    <b>{`${t("last_demand_generated_on")} ${moment(
                      new Date(Number(last_generated_demand_date?.demand_date))
                    ).format("DD/MM/YYYY")} ( ${moment(
                      new Date(
                        Number(last_generated_demand_date?.generated_date)
                      )
                    )
                      .utc()
                      .format("DD/MM/YYYY hh:mm:ss A")} )`}</b>
                  ) : (
                    <b>View Last Demand Generated List</b>
                  )
                }
                // key={"0"}
              >
                <DemandGeneratedListCollapse
                  log_view_item_id={log_view_item_id}
                  set_log_view_item={set_log_view_item}
                  department_id={department_id}
                  sub_department_id={sub_department_id}
                  month={month}
                  table_ref={table_ref}
                  set_pressed_record_index={set_pressed_record_index}
                  set_page_number_collapse={set_page_number_collapse}
                  page_number_collapse={page_number_collapse}
                  page_size={page_size}
                  set_page_size={set_page_size}
                />
              </Collapse.Panel>
            </Collapse>
            {/* )} */}
          </Box>
        </div>

        <Wrapper>
          <div ref={table_ref}>
            {/* <Table
              width="100%"
              rowKey="id"
              group="demand"
              loading={loading || demand_loading}
              data={demands}
              columns={column1}
              hasSearch={false}
              hasPagination={false}
              fetch={get_demand}
              query={query}
              // rowSelection={{
              //   selectedRowKeys: alreadySelectedRows,
              //   onChange: (keys, value) => {
              //     setAlreadySelectedRows(keys);
              //     setAlreadySelectedData(value);
              //   },
              //   // selections: [
              //   //   Table.SELECTION_ALL,
              //   //   Table.SELECTION_NONE,
              //   //   Table.SELECTION_INVERT,
              //   // ],
              // }}
              headerActions={[]}
              emptyMessage={t("table:empty_demand")}
              headerColor={"white"}
            /> */}

            {pressed_record_index || pressed_record_index === 0 ? (
              <div className="d-flex content-center justify-center-left">
                <div className="demand-table-header-for-text">
                  {/* to show the text data as per the data index that we clicked */}
                  {demand_for_previous_month?.length > 0 ? (
                    <b className="demand-header-note">{`${"Demand Generated"} ${moment(
                      new Date(
                        Number(
                          demand_for_previous_month?.[pressed_record_index]
                            ?.demand_date
                        )
                      )
                    ).format("DD/MM/YYYY")} ( ${moment(
                      new Date(
                        Number(
                          demand_for_previous_month?.[pressed_record_index]
                            ?.generated_date
                        )
                      )
                    )
                      .utc()
                      .format("DD/MM/YYYY hh:mm:ss A")} )`}</b>
                  ) : (
                    ""
                  )}
                  {note.show ? `(Note - ${note.message})` : ""}
                </div>
              </div>
            ) : (
              ""
            )}

            <Table
              loading={loading || demand_loading}
              dataSource={demands}
              columns={column1}
            />
          </div>
        </Wrapper>
      </Box>

      {/* <HStack justifyContent={"flex-end"} mt="5">
        <Pagination
          // showTotal={}
          // total={demand?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
          // defaultPageSize={parseInt(pageSizeOptions[0])}
          // pageSizeOptions={pageSizeOptions}
        />
      </HStack> */}

      <Modal
        isOpen={email_modal_open}
        onClose={handleEmailModalClose}
        header={t("bulk_email_processing_confirmation")}
        component={
          <BulkMailConfirmation
            close={handleEmailModalClose}
            data_source={alreadySelectedData}
            handleView={handleView}
            formatDepartment={formatDepartment}
            formatMonth={formatMonth}
            formatDate={formatDate}
            // fetch_data={get_demand_generations({
            //   page_limit: page_limit,
            //   page_number: page_number,
            //   date: month,
            // })}
          />
        }
        source={{}}
        width={"1000px"}
      />
      <Modal
        isOpen={excel_modal_open}
        onClose={handleExcelModalClose}
        header={t("bulk_processing")}
        component={<RecordCollectionExcel close={handleExcelModalClose} />}
        source={{}}
        width={"1000px"}
      />
      <div style={{ display: "none" }} id="dcb-report" ref={printRef}>
        {show_report_modal && <ReportDownload items={values} />}
      </div>
    </>
  );
};
export default DemandGenerationList;
