import { gql } from "@apollo/client";

export const create_dividend_mutation = gql`
  mutation createDividend($data: dividend_input) {
    createDividend(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_dividend_mutation = gql`
  mutation updateDividend($id: String!, $data: dividend_input) {
    updateDividend(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_dividend_mutation = gql`
  mutation deleteDividend($id: String!) {
    deleteDividend(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_dividends_query = gql`
 query getDividends($financial_year: Int, $status: String) {
  getDividends(financial_year: $financial_year, status: $status) {
    error
    total_dividend
    dividends {
      id
      status
      dividend_payout_id
      member_id
      name
      member_number
      dividend_amount
      total_overdue_amount
      adjustable_overdue_amount
      payable_dividend_amount
      log {
        share_amount
        from_date
        to_date
        no_of_days
      }
    }
  }
}
`;

export const get_one_dividend_query = gql`
  query getDividend($id: String!) {
    getDividend(id: $id) {
      id
      society_id
      status
      total_revenue
      total_profit
      dividend_percentage
      year
      resolution_id
      error {
        status_code
        message
      }
    }
  }
`;

export const dividend_process_query = gql`
  query processDividend($id: String!) {
    processDividend(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
