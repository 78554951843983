import { Pagination, Table } from "antd";
import moment from "moment-timezone";
import { HStack, Pressable, Text } from "native-base";
import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { MdRemoveRedEye } from "react-icons/md";
import { amountFormat } from "../../../../helpers/utils";
import {
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
} from "../../../../services/redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  get_demand_generations_query,
  query_demand_generation_logs,
} from "services/redux/slices/demand/graphql";
import { useLocation, useParams } from "react-router-dom";

const DemandGeneratedListCollapse = (props) => {
  const {
    closeDemandCollapse,
    closeDemandListCollapse,
    setActiveKey,
    table_ref,
    month,
    department_id,
    sub_department_id,
    set_log_view_item,
    log_view_item_id,
    set_pressed_record_index,
    set_page_number_collapse,
    page_number_collapse,
    page_size,
    set_page_size,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { is_collection_disable } = location.state || {};

  const {
    items: demand_for_previous_month,
    loading: demand_for_previous_month_loading,
    pagination: demand_for_previous_month_pagination,
  } = useDynamicSelector("getDemandGenerationLogs");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const handlePageChange = (newPage, newPageSize) => {
    set_page_number_collapse(newPage);
    set_page_size(newPageSize);
  };

  const scrollToSection = () => {
    if (table_ref?.current) {
      table_ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const get_demand = (demand_generation_log_id) => {
    let key = [{ key: "getDemandGenerations", loading: true }];
    let query = get_demand_generations_query;
    let variables = {
      demand_generation_log_id:
        demand_generation_log_id || demand_for_previous_month?.[0]?.id,
      sub_department_id_list: sub_department_id,
      department_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDemandLogs = () => {
    const keys = [{ key: "getDemandGenerationLogs", loading: true }];
    const variables = {
      date: month,
      page_number: page_number_collapse,
      page_limit: page_size,
    };
    dispatch(dynamicRequest(keys, query_demand_generation_logs, variables));
  };

  useEffect(() => {
    if(page_size, page_number_collapse){
      getDemandLogs();
    }
  }, [ page_size, page_number_collapse]);

  useEffect(()=>{
    set_page_number_collapse(1);
    if(page_number_collapse===1){
      set_pressed_record_index(0)
    }
  },[month])

  useEffect(()=>{
    if(page_size && page_number_collapse){
      get_demand()
    }
  },[page_size, page_number_collapse])

    const decide_option_visible = (index)=>{
      if(index === 0 && moment(month).format("MMM") !==
      moment(getSocietyAccountingDay?.current_accounting_day).format("MMM")){
        return true
      }else{
        return false
      }
    }

  const columns = [
    {
      title: t("table:demand_generated_on"),
      key: "",
      align: "center",
      width: "350px",
      render: (data) =>{
        return `${moment(Number(data.demand_date)).format("DD/MM/YYYY")} (
      ${moment(
        new Date(
          Number(data?.generated_date)
        )
      )
        .utc()
        .format("DD/MM/YYYY hh:mm:ss A")})`
      }
    },
    {
      title: t("table:total_members"),
      dataIndex: "total_members",
      key: "total_members",
      align: "left",
      width: "200px",
    },
    {
      title: t("table:total_amount"),
      key: "member_amount",
      align: "right",
      width: "350px",
      render: (data) => (
        <Text alignItems={"right"}>{amountFormat(data?.total_demand)}</Text>
      ),
    },
    {
      title: t("table:actions"),
      key: "actions",
      align: "center",
      width: "200px",
      render: (record, value, index) => (
        <Pressable
          onPress={() => {
            set_pressed_record_index(index);
            console.log("indexdata: ", index);

            //  To decide the user able to view or not
            set_log_view_item(record?.id);
            history.push({
              pathname: `/demand-generation/${record?.generated_date}`,
              state: { is_log_collection_enable: decide_option_visible(index)},
            });
            get_demand(record?.id);
            scrollToSection();
          }}
        >
          <Text style={{ color: "blue" }} className="act-as-link">
            {t("view")}
          </Text>
        </Pressable>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={demand_for_previous_month}
        columns={columns}
        pagination={false}
        loading={demand_for_previous_month_loading}
      />
      <HStack justifyContent={"flex-end"} mt="5">
        <Pagination
          total={demand_for_previous_month_pagination?.total_count}
          showSizeChanger={true}
          current={page_number_collapse}
          onChange={handlePageChange}
          onShowSizeChange={handlePageChange}
        />
      </HStack>
    </div>
  );
};

export default DemandGeneratedListCollapse;
